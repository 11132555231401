<template>
	<div class="open-account-userinfo">

		<h1 class="h1_title">{{translate('userinfo_identity_information')}}</h1>

		<van-form @submit="nextStep()" :rules="rules">
			<h4 class="h4_title">{{translate('document_upload')}}</h4>
			<van-row class="label-content">
				<van-col span="12" style="text-align:center;">
					<van-image
						width="150"
						height="100"
						fit="contain"
						:src="idcode_front_url"
						@click="call_idcode_front_pic"
					/>
				</van-col>
				<van-col span="12" style="text-align:center;">
					<van-image
						width="150"
						height="100"
						fit="contain"
						:src="idcode_back_url"
						@click="call_idcode_back_pic"
					/>
				</van-col>
			</van-row>

			<h4 class="h4_title">{{translate('personal_information')}}</h4>
			
			<van-field 
				v-model="formItem.name" 
				:label="translate('userinfo_name')"
				label-width="80px"
				:placeholder="translate('please_fill_in_your_name')"
				required
				:rules="rules.name"
				/>

			<van-field
				readonly
				clickable
				:label="translate('userinfo_gender')"
				label-width="80px"
				:value="genders[indexGender]"
				:placeholder="translate('please_choose')"
				@click="showGender = true"
				required
				:rules="rules.gender"
			/>
			<van-popup v-model="showGender" round position="bottom">
				<van-picker
					:title="translate('userinfo_gender')"
					show-toolbar
					:columns="genders"
					:default-index="indexGender"
					@cancel="showGender = false"
					@confirm="onConfirmGender"
				/>
			</van-popup>

			<van-field
				readonly
				clickable
				:label="translate('userinfo_birthday')"
				label-width="80px"
				:value="dateSelect"
				:placeholder="translate('please_choose')"
				@click="showDate = true"
				required
				:rules="rules.date"
			/>
			<van-popup v-model="showDate" round position="bottom">
				<van-datetime-picker
					v-model="currentBirthday"
					type="date"
					:title="translate('select_date_of_birth')"
					:min-date="minBirthday"
					:max-date="maxBirthday"
					@cancel="showDate = false"
					@confirm="onConfirmBirthday"
				/>
			</van-popup>	

			<van-field
				v-model="formItem.idcode" 
				:label="translate('userinfo_identity_number')" 
				label-width="80px"
				:placeholder="translate('please_fill_in_the_id_number')"
				required
				:rules="rules.idcode"
				/>

			<van-field
				readonly
				clickable
				:label="translate('userinfo_marital_status')"
				label-width="80px"
				:value="marriages[indexMarriage]"
				:placeholder="translate('please_choose')"
				@click="showMarriage = true"
				required
				:rules="rules.marriage"
			/>
			<van-popup v-model="showMarriage" round position="bottom">
				<van-picker
					:title="translate('userinfo_marital_status')"
					show-toolbar
					:columns="marriages"
					:default-index="indexMarriage"
					@cancel="showMarriage = false"
					@confirm="onConfirmMarriage"
				/>
			</van-popup>

			<van-field 
				v-model="formItem.email" 
				:label="translate('userinfo_id_card_email')" 
				label-width="80px"
				:center="true"
				:placeholder="translate('userinfo_please_fill_the_email')" 
				required
				:rules="rules.email"
				>
				<template #button>
					<van-button size="mini" type="primary" block native-type="button" :disabled="disabledSendCode" @click="sendCode">{{send_text}}</van-button>
				</template>
			</van-field>

			<van-field
				v-model="formItem.email_code" 
				:label="translate('userinfo_verification_code')" 
				label-width="80px"
				:placeholder="translate('userinfo_fill_verification_code')"
				required
				:rules="rules.email_code"
				/>
			
			<div class="common_block">
				<van-button type="warning" block round native-type="submit">{{translate('userinfo_next_step')}}</van-button>
			</div>
		</van-form>
		
		<van-loading type="spinner" v-if="showLoading" color="#1989fa" style="position:fixed;top:50%;left:50%;transform: translate(-50%, -50%)" />	
	</div>
</template>

<script>
	export default {
		name: 'account_userinfo',
		data() {
			return {
				formItem: {
					name: "",
					gender: this.translate('userinfo_man'),
					birthday: "",
					idcode: "",
					email: "",
					email_code: "",
					is_marriage: this.translate('userinfo_unmarried'),
					idcode_front_pic: "",
					idcode_back_pic: "",
				},

				rules: {
					name: [
						{
							required: true,
							message: this.translate('userinfo_please_fill_your_name'),
							trigger: 'onBlur'
						}
					],
					telephone: [
						{
							required: true,
							message: this.translate('please_fill_in_the_phone_number'),
							pattern: /^[1][3,4,5,7,8][0-9]{9}$/,
							trigger: 'onBlur'
						}
					],
					email: [
						{
							required:true,
							message: this.translate('userinfo_please_enter_the_correct_email'),					
							pattern: /^([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/,
							trigger: 'onBlur'
						}
					],
					idcode: [
						{
							required: true,
							message: this.translate('userinfo_please_fill_id_number'),
							//pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
							trigger: 'onBlur'
						}						
					],
					gender: [
						{ 
							required: true, 
							trigger: 'onBlur', 
							message: this.translate('please_select_gender') 
						}
					],
					date: [
						{ 
							required: true, 
							trigger: 'onBlur', 
							message: this.translate('please_select_date_of_birth') 
						}
					],
					marriage: [
						{ 
							required: true, 
							trigger: 'onBlur', 
							message: this.translate('userinfo_please_marital_status') 
						}
					],
					
					email_code: [
						{ 
							required: true, 
							message: this.translate('userinfo_please_enter_the_correct_email'),
							pattern: /(^\d{6}$)/,
							trigger: 'onBlur'
						}
					]
				},

				token: "",

				indexGender: 0,
				showGender: false,

				indexMarriage: 0,
				showMarriage: false,
				
				showDate: false,
				dateSelect: "",

				resourceURL: this.$axios.defaults.resourceURL,
				idcode_front_url: this.$axios.defaults.resourceURL + "images/icon_id_u.png",
				idcode_back_url: this.$axios.defaults.resourceURL + "images/icon_id_g.png",
				
				genders: [this.translate('userinfo_man'), this.translate('userinfo_woman')],
				marriages: [this.translate('userinfo_unmarried'), this.translate('userinfo_married')],

				minBirthday: new Date(1930, 0, 1),
				maxBirthday: new Date(),
				currentBirthday: new Date(),

				send_text: this.translate('userinfo_send_verification_code'),
				second: 60,
				clock: "",
				disabledSendCode: false,

				showLoading: false,

			}
		},
		mounted() {
			if (this.$route.query.token == "" || this.$route.query.token == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.token = this.$route.query.token
				
				// 将方法绑定到window下面，提供给外部调用
				window.set_idcode_front_pic = this.set_idcode_front_pic
				window.set_idcode_back_pic = this.set_idcode_back_pic
				
				this.init()
			}
		},
		methods: {

			doLoop() {
				this.second--;
				if(this.second > 0){
					this.send_text = this.second + this.translate('second')
				}else{
					clearInterval(this.clock); //清除js定时器
					this.disabledSendCode = false;
					this.send_text = this.translate('send_the_verification_code');
					this.second = 60; //重置时间
				}
			},


			sendCode() {

				var regEmail=/^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
				
				if(this.formItem.email == "" || !regEmail.test(this.formItem.email)){
					this.$toast.fail(this.translate('please_enter_a_correct_email'))
					return false
				}

				this.disabledSendCode = true

				this.showLoading = true

				this.$axios({
					method: 'post',
					url: 'account_open/sendemailcode', 
					data: {
						token: this.token,
						email: this.formItem.email
					}
				}).then ((res) => {
					if (res.success) {

						this.showLoading = false

						this.$toast.success(this.translate('userinfo_verification_code_sent_success'));
						
						this.send_text = this.second + this.translate('second')
						this.clock = setInterval(this.doLoop, 1000); //一秒执行一次
						
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			},

			init(){
				//根据TOKEN获取信息
				this.$axios({
					method: 'post',
					url: 'account_open/info',
					data: {
						token: this.token
					}
				}).then ((res) => {
					if (res.success) {
						
						if (res.data.status == 2) {
							//	待审核
							this.$router.replace('/account/success')
						} else if (res.data.status == 3) {
							//	不通过
							this.$router.replace('/account/fail?token=' + this.token)
						} else {
							var userinfo_data = res.data.userinfo_data		
							
							if (userinfo_data.length != 0) {
								this.formItem.name = userinfo_data.name
								this.formItem.gender = userinfo_data.gender
								this.formItem.birthday = userinfo_data.birthday
								this.formItem.idcode = userinfo_data.idcode
								this.formItem.email = userinfo_data.email
								this.formItem.is_marriage = userinfo_data.is_marriage
								this.formItem.idcode_front_pic = userinfo_data.idcode_front_pic
								this.formItem.idcode_back_pic = userinfo_data.idcode_back_pic
								
								this.genders.forEach((item, key) => {
									if(item == userinfo_data.gender){
										this.indexGender = key
									}								
								})
								
								this.marriages.forEach((item, key) => {
									if(item == userinfo_data.is_marriage){
										this.indexMarriage = key
									}								
								})
								
								this.dateSelect = userinfo_data.birthday
								
								if (userinfo_data.idcode_back_url) {
									this.idcode_back_url = userinfo_data.idcode_back_url
								}
								
								if (userinfo_data.idcode_front_url) {
									this.idcode_front_url = userinfo_data.idcode_front_url
								}
							}
						}
					} else {
						this.$toast.fail(res.error_text)
					}
				})
			},

			call_idcode_front_pic(){
				
                if (window.JsObject != null) {
                    window.JsObject.callIdcodeFrontPic();
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "idcode_front_pic"});
                }
			},

			call_idcode_back_pic(){
                if (window.JsObject != null) {
                    window.JsObject.callIdcodeBackPic();
                }

                if (window.webkit != null) {
                    window.webkit.messageHandlers.jsToOcWithPrams.postMessage({"camera": "idcode_back_pic"});
                }
			},
			
			/*   
             * 参数path：
             *     上传图片在服务器的路径
             */
            set_idcode_front_pic(path){

				this.$axios({
					method: 'post',
					url: 'account_open/resize',
					data: {
						img: path
					}
				}).then ((res) => {
					if (res.success) {
						this.idcode_front_url = res.thumb
						this.formItem.idcode_front_pic = path
					}else{
						this.$toast.fail(this.translate('userinfo_capture_failed'))
					}
				})
			},
			
			set_idcode_back_pic(path){

				this.$axios({
					method: 'post',
					url: 'account_open/resize',
					data: {
						img: path
					}
				}).then ((res) => {
					if (res.success) {
						this.idcode_back_url = res.thumb
						this.formItem.idcode_back_pic = path
					}else{
						this.$toast.fail(this.translate('userinfo_capture_failed'))
					}
				})

			},
			onConfirmBirthday(val) {
				var year = val.getFullYear()
				
				var month = val.getMonth() + 1
				if (month < 10) {
					month = '0' + month
				}
				
				var day = val.getDate()
				if (day < 10) {
					day = '0' + day
				}
				
				let value = year + '-' + month + '-' + day
				
				this.formItem.birthday = value
				
				this.dateSelect = value
				
				this.showDate = false
			},

			onConfirmGender(value, index) {
				if (value) {
					this.formItem.gender = value
					
					this.indexGender = index
				}
				
				this.showGender = false
			},

			onConfirmMarriage(value, index) {
				if (value) {
					this.formItem.is_marriage = value
					
					this.indexMarriage = index
				}
				
				this.showMarriage = false
			},

			nextStep() {

				if(this.formItem.idcode_front_pic == '' || this.formItem.idcode_back_pic == ''){
					this.$toast.fail(this.translate('please_upload_idcard_front_and_back'))
					return false
				}

				console.log(this.formItem)

				this.$axios({
					method: 'post',
					url: 'account_open/alter',
					data: {
						token: this.token,
						userinfo_data: JSON.stringify(this.formItem)
					}
				}).then ((res) => {
					if (res.success) {
						this.$router.push('/account/type?token=' + this.token)
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			}
		},
	}
</script>